import React from 'react'
import {Card2} from './component/Card2'
import {useReport} from './context/ReportProvider'

const Conversion = () => {

  const {
    callReceived,
    formsReceived,
    campagne: {messagesReceived, contacts},
  } = useReport()


  return (
      <>
        <div className="row p-5">
          <div className="col-md-3 mb-xl-10">
            <Card2 title={'conversions'} stats={null} svgIcon={null}/>
          </div>
          <div className="col-md-3 mb-xl-10">
            <Card2 title={'appels'} stats={callReceived} svgIcon={{
              type: 'duotune',
              path: '/media/icons/duotune/communication/com019.svg',
            }}/>
          </div>
          <div className="col-md-3 mb-xl-10">
            <Card2 title={'formulaires'} stats={formsReceived} svgIcon={{
              type: 'duotune',
              path: '/media/icons/duotune/general/gen061.svg',
            }}/>
          </div>
          <div className="col-md-3 mb-xl-10">
            <Card2 title={'contacts'} stats={contacts} svgIcon={{
              type: 'duotune',
              path: '/media/icons/duotune/communication/com020.svg',
            }}/>
          </div>

        </div>
      </>
  )
}
export {Conversion}