import axios from 'axios'
import {Dispatch, SetStateAction, useState} from 'react'
import {API_URL, localStorageValue} from '../../all-pages'


type listOfProducts = {
    id: number;
    name: string;
    description: string;
}

type PropsProducts = {
    name: string;
    date: string;
    description: string;
    status: number;
    treaty: number;
}

type PropsProductRequest = {
    id: number;
    products: string;
    trait: number
}

type Props ={
    setProductsData: Dispatch<SetStateAction<PropsProducts[]>>
}

const GET_PRODUCTS = process.env.REACT_APP_GET_PRODUCTS;

export default function GetProducts({setProductsData}: Props) {

    axios({

        method: "GET",
        url: `${API_URL}${GET_PRODUCTS}`,
        headers: {
            'Authorization': `Bearer ${localStorageValue}`,
        },
    })
        .then((response) => {

            const DATA= response.data;
            const productRequest = DATA.demandeProducts,
                products = DATA.products,
                listOfProducts: listOfProducts[] = DATA.products_list;

            const values: PropsProductRequest[] = Object.values(productRequest)

            const products1: PropsProducts[] = [];
            const keys: string[] = [];

            listOfProducts.forEach((product, index) => {

                let status: number = products[`${product.name}`];
                let date: string = products[`${product.name}Date`];
                let name: string = product.name;
                let description: string = product.description;
                let treaty: number = -1;

                values.forEach((i) => {
                    if (i.products == product.name) {
                        treaty = i.trait;
                        keys.push(product.name);
                    }
                });

                if (name == "Ereputaion") name = "E-réputation";

                products1.push({status, date, name, description, treaty});

            });

            setProductsData(products1);
        })
        .catch((error) => {
            console.log(error)
        });
}