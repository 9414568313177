import {
    origineGeographiqueVille,
    seoTopPages,
    topMotsCles,
} from '../../../modules/products/statistiques/ts'
import {Period} from './ReportProvider'
import {createContext} from 'react'

export interface PropsPersonalInformation {
    firstName: string,
    lastName: string,
    email: string,
}

export interface InterfaceContext {
    period: Period;
    conversion: number;
    seo: {
        nombrePagesVues: number;
        nombreVisites: number;
        mostViewedPage: seoTopPages[];
        geographicOriginByCity: origineGeographiqueVille[];
        byChannels: topMotsCles[]
    };
    callReceived: number;
    formsReceived: number;
    campagne: {
        messagesReceived: number;
        contacts: number;
    },
    loadingStatus: 'idle' | 'pending' | 'fulfilled' | 'rejected',
    administration: {
        data: AdministrationData,
        loading: boolean
    },
    domainName: string
}

export const initialState: InterfaceContext = {
    period: {
        start: '', end: '',
    },
    conversion: 0,
    seo: {
        nombrePagesVues: 0,
        nombreVisites: 0,
        mostViewedPage: [],
        geographicOriginByCity: [],
        byChannels: [],
    },
    callReceived: 0,
    formsReceived: 0,
    campagne: {
        messagesReceived: 0,
        contacts: 0,
    },
    loadingStatus: 'idle',
    administration: {
        data: {
            partage_media_1: {
                title: '',
                sub_title: '',
                text: '',
                icon: null,
                image: '',
                button: {
                    title: '',
                    href: '',
                },
            },
            partage_media_2: {
                title: '',
                sub_title: '',
                text: '',
                icon: null,
                image: null,
                button: {
                    title: '',
                    href: '',
                },
            },
            position_6: {
                title: '',
                sub_title: '',
                text: '',
                icon: '',
                image: null,
                button: {
                    title: '',
                    href: '',
                },
            },
            position_header: {
                title: '',
                sub_title: '',
                text: '',
                icon: '',
                image: null,
                button: null,
            },
            position_top_5: {
                title: '',
                sub_title: '',
                text: '',
                icon: '',
                image: null,
                button: null,
            },
            pub_meddle: {
                title: '',
                sub_title: '',
                text: '',
                icon: '',
                image: null,
                button: {
                    title: '',
                    href: '',
                },
            },
        },
        loading: true,
    },
    domainName: ''
}

export const ReportContext = createContext<InterfaceContext>(initialState)

export interface Button {
    title: string;
    href: string;
}

export interface Position {
    button: Button | null;
    icon: string | null;
    image: string | null;
    sub_title: string;
    text: string;
    title: string;
}

export interface AdministrationData {
    partage_media_1: Position;
    partage_media_2: Position;
    position_6: Position;
    position_header: Position;
    position_top_5: Position;
    pub_meddle: Position;
}
