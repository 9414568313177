import React, {FC, useContext, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {ChartsWidget7, ChartsWidget8} from '../../../_metronic/partials/widgets'
import {ProductContext, ProductReturn} from '../../GeneraleVariables'
import {ValueType} from 'rsuite/cjs/DateRangePicker/types'
import {subDays} from 'date-fns'
import {RangeValue, usePeriod} from '../../modules/all-pages'
import {QueryFunctionContext, QueryKey, useQueries} from 'react-query'
import ChartDashboard1 from './components/ChartDashboard1'
import {ChartDashboard4} from './components/ChartDashboard4'
import {ContactsDashboard} from './components/ContactsDashboard'
import SetupAxios from '../../../setup/axios/SetupAxios'
import {Button} from '../report/context/types'

interface Period {
    start: string;
    end: string;
}


interface InterfaceData {
    conversion: number
    numberVisit: number
    chartOptions: {
        series: number[]
        categoriesXAxis: string[]
    }
    allCallsReceived: number
    allFormsReceived: number
    announcement: {
        button: Button | null;
        icon: {
            src: string
            title: string
            text: string
        }[] | []
        image: string | null;
        sub_title: string;
        text: string;
        title: string;
    } | null
}

const DASHBOARD_CONVERSION = process.env.REACT_APP_DASHBOARD_CONVERSION || 'conversion'
const CALL_TRACKING = process.env.REACT_APP_CALL_TRACKING_GENERAL || 'calltraking-statistic'
const WEB_FORMS = process.env.REACT_APP_WEB_FORMS_HOME_WEB_FORMS || 'statistiques-generales'
const STATISTICS_VISIT = process.env.REACT_APP_STATISTICS_VISIT || 'statistics-visit'
const CALL_LOG = process.env.REACT_APP_CALL_TRACKING_CALL_LOG || 'calltraking-journal-appel'
const ANNOUNCEMENT = process.env.REACT_APP_DASHBOARD_ANNOUNCEMENT || 'v2/annonce-homepage'

const fetchDashboardData = async (endpoint: string, period: Period) => {
    const parameter = period ? `?start=${period.start}&end=${period.end}` : ''
    const response = await SetupAxios.get(`${endpoint}${parameter}`)
    return response.data
}

const DashboardPage: FC = () => {

    const productsStatus = useContext<ProductReturn[]>(ProductContext)

    const [rangeValue, setRangeValue] = React.useState<ValueType>([subDays(new Date(), 29), new Date()]),
        [count, setCount] = useState<number>(0),
        [loadingData, setLoadingData] = useState<boolean>(false),
        [data, setData] = useState<InterfaceData>({
            conversion: 0,
            numberVisit: 0,
            chartOptions: {
                series: [],
                categoriesXAxis: [''],
            },
            allCallsReceived: 0,
            allFormsReceived: 0,
            announcement: null
        }),
        [dateTimeStart, dateTimeEnd] = rangeValue,
        excludedNames = ['Mailing', 'Agenda', 'Ereputaion', 'Statistiques']

    const period = usePeriod(dateTimeStart, dateTimeEnd)

    const statusTrue = useMemo(
        () => productsStatus.filter(item =>
            item.status === 1 && !excludedNames.includes(item.name),
        ),
        [productsStatus],
    )

    const onSuccessReq = (data: any, name: string) => {
        setData(prevData => {
            if (name === 'Calltraking') {
                return {...prevData, allCallsReceived: data}
            } else if (name === 'Webformulaires') {
                return {...prevData, allFormsReceived: data}
            }
            return prevData
        })
        setLoadingData(false)
    }

    const onSuccessReq2 = (dataReq: any, name: string) => {

        setData(prevData => {
            if (name === 'conversion') {
                return {...prevData, conversion: dataReq}
            } else if (name === 'statistics_visit') {
                return {
                    ...prevData,
                    numberVisit: dataReq.seoNombreVisiteurs,
                    chartOptions: {
                        series: dataReq.seoChartVisiteurs.total,
                        categoriesXAxis: dataReq.seoChartVisiteurs.labels,
                    },
                }
            } else if (name === 'call_log') {
                return {
                    ...prevData,
                    callLog: dataReq,
                }
            } else if (name === 'announcement') {
                return {
                    ...prevData, announcement: dataReq.data.annonce_homepage_1
                }
            } else {

                return {
                    ...prevData
                }
            }
        })
        setLoadingData(false)
    }

    const onError = (error: any) => {
        console.log('query: ', {error})
    }

    const queries = useMemo(() => {
        return statusTrue.map(product => {
            const queryKey: QueryKey = [`dashboard_${product.name.toLowerCase()}`, period]
            const endpoint = product.name === 'Calltraking' ? CALL_TRACKING : WEB_FORMS
            return {
                queryKey,
                queryFn: (context: QueryFunctionContext<QueryKey, any>) => fetchDashboardData(endpoint, period),
                enabled: !!period,
                select: (data: any) => (product.name === 'Calltraking' ? data.generalAppelRecu : data.runningTotalFormReceived),
                onSuccess: (data: any) => onSuccessReq(data, product.name),
                onError,
            }
        })
    }, [productsStatus, period])

    const queries2 = useMemo(() => {
        return [
            {name: 'conversion', endpoint: DASHBOARD_CONVERSION},
            {name: 'statistics_visit', endpoint: STATISTICS_VISIT},
            {name: 'call_log', endpoint: CALL_LOG},
            {name: 'announcement', endpoint: ANNOUNCEMENT},
        ].map(query => {
            const queryKey: QueryKey = [`dashboard_${query.name.toLowerCase()}`, period]
            const endpoint = query.endpoint
            return {
                queryKey,
                queryFn: (context: QueryFunctionContext<QueryKey, any>) => fetchDashboardData(endpoint, period),
                enabled: !!period,
                select: (data: any) => {
                    return data
                },
                onSuccess: (data: any) => onSuccessReq2(data, query.name),
                onError,
            }
        })
    }, [period])

    useQueries([...queries, ...queries2])

    return (
        <>

            {/* RangeValue */}
            <div className="row mb-8">
                <div className="col-12 d-flex justify-content-end align-items-center">
                    <RangeValue rangeValue={rangeValue}
                                setRangeValue={setRangeValue}
                                count={count}
                                setCount={setCount}
                                loadingData={loadingData}
                                setLoadingData={setLoadingData}
                                className={'d-flex justify-content-end align-items-center'}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-6 col-lg-3 mb-5">
                    <ChartDashboard1 bg={'#e1006d'}
                                     iconPath={'/media/icons/duotune/general/gen060.svg'}
                                     body={{total: data.numberVisit, text: 'visites'}}
                                     to={'/statistiques/s-seo'}
                        // footer={{start: period.start, end: period.end}}
                    />
                </div>
                <div className="col-6 col-lg-3 mb-5">
                    <ChartDashboard1 bg={'#0094f3'}
                                     iconPath={'/media/icons/duotune/arrows/arr029.svg'}
                                     body={{total: data.conversion, text: 'conversions'}}
                        // footer={{start: period.start, end: period.end}}
                    />
                </div>
                <div className="col-6 col-lg-3 mb-5">
                    <ChartDashboard1 bg={'#e1006d'}
                                     iconPath={'/media/icons/duotune/general/gen005.svg'}
                                     body={{total: data.allFormsReceived, text: 'formulaires'}}
                                     to={'/web-formulaires/tableau-de-bord-wf'}
                        // footer={{start: period.start, end: period.end}}
                    />
                </div>
                <div className="col-6 col-lg-3 mb-5">
                    <ChartDashboard1 bg={'#0094f3'}
                                     iconPath={'/media/icons/duotune/communication/com014.svg'}
                                     body={{total: data.allCallsReceived, text: 'appels'}}
                                     to={'/call-tracking/journal-appels'}
                        // footer={{start: period.start, end: period.end}}
                    />
                </div>
            </div>

            <div className="row d-flex justify-content-center">
                <div className="col-xl-4 card card-xl-stretch">
                    <ContactsDashboard/>
                </div>
                <div className="col-xl-8">
                    <ChartDashboard4 className={'card-xl-stretch'}
                                     chartOptions={data.chartOptions}
                    />
                </div>
            </div>

            {/*<div className="row">*/}
            {/*  <div className="col-lg-12">*/}
            {/*    <ChartDashboard4 className={'card-xl-stretch'}*/}
            {/*                     chartOptions={data.chartOptions}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*</div>*/}

            {/*<Table thead={*/}
            {/*  <>*/}
            {/*    {*/}
            {/*      headers.map((row, index) => (*/}
            {/*        <th key={index}*/}
            {/*            className={`${!index ? 'ps-5' : ''} fw-bolder text-muted bg-light min-w-175px`}>*/}
            {/*          {row.label}*/}
            {/*        </th>*/}
            {/*      ))*/}
            {/*    }*/}
            {/*    <th />*/}
            {/*  </>*/}
            {/*}*/}
            {/*       tbody={*/}
            {/*         <>*/}
            {/*           {*/}
            {/*             data.callLog.slice(0, 5)`.map((item, index) =>*/}
            {/*               <tr key={index}>*/}
            {/*                 <td>{item.called_at}</td>*/}
            {/*                 <td>{item.cleverphone}</td>*/}
            {/*                 <td>{item.callFrom}</td>*/}
            {/*                 <td>{item.transferTo}</td>*/}
            {/*                 <td>{item.transactiontype}</td>*/}
            {/*                 <td>{FunctionsCallTracking.SecondsToTime(item.duration)}</td>*/}
            {/*               </tr>,*/}
            {/*             )*/}
            {/*           }*/}

            {/*         </>*/}
            {/*       }*/}
            {/*/>*/}

            <div className="row my-5">
                <div className="col-12">
                    {/*begin::Engage widget*/}
                    <div className="card h-lg-100"
                         style={{background: 'linear-gradient(112.14deg, rgb(12, 147, 225) 0%, rgb(225, 0, 109) 100%)'}}
                    >
                        {/*begin::body*/}
                        <div className="card-body">
                            <div className="row align-items-center">
                                <div className="col-8 pe-0 mb-5 mb-sm-0">
                                    <div className="d-flex justify-content-between h-100 flex-column pt-xl-5 pb-xl-2 ps-xl-7">
                                        {/*begin::Container*/}
                                        <div className="mb-7">
                                            <div className="mb-6">
                                                <h3 className="fs-2x fw-semibold text-white">{data.announcement?.title}</h3>
                                                <span className="fw-semibold text-white opacity-75">{data.announcement?.text}</span>
                                            </div>
                                            {/*begin::Items*/}
                                            <div className="d-flex align-items-center flex-wrap d-grid gap-2">

                                                {
                                                data.announcement?.icon.map((item, index) => (
                                                        <div className="d-flex align-items-center me-5 me-xl-13" key={index}>
                                                            <div className="symbol symbol-30px symbol-circle me-3">
                                                                <div className="symbol-label"
                                                                     style={{background: 'rgba(255, 255, 255, 0.15)'}}
                                                                >
                                                                    <img src={`${item.src}`}
                                                                         className="w-20px"
                                                                         alt=""/>

                                                                    {/*<KTSVG*/}
                                                                    {/*    path="/media/icons/duotune/abstract/abs027.svg"*/}
                                                                    {/*    className="svg-icon svg-icon-3 svg-icon-white"/>*/}
                                                                </div>
                                                            </div>
                                                            <div className="m-0">
                                                                <a href="#"
                                                                   className="text-white opacity-75 fs-8">{item.title}</a>
                                                                <span
                                                                    className="fw-bold text-white fs-7 d-block">{item.text}</span>
                                                            </div>
                                                        </div>

                                                    ))
                                                }


                                                {/*/!*begin::Item*!/*/}
                                                {/*<div className="d-flex align-items-center me-5 me-xl-13">*/}
                                                {/*    <div className="symbol symbol-30px symbol-circle me-3">*/}
                                                {/*        <div className="symbol-label"*/}
                                                {/*             style={{background: 'rgba(255, 255, 255, 0.15)'}}*/}
                                                {/*        >*/}
                                                {/*            <KTSVG path="/media/icons/duotune/abstract/abs027.svg"*/}
                                                {/*                   className="svg-icon svg-icon-3 svg-icon-white"/>*/}
                                                {/*        </div>*/}
                                                {/*    </div>*/}
                                                {/*    <div className="m-0">*/}
                                                {/*        <a href="#" className="text-white opacity-75 fs-8">Clients</a>*/}
                                                {/*        <span className="fw-bold text-white fs-7 d-block">350</span>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                                {/*/!*end::Item*!/*/}
                                                {/*/!*begin::Item*!/*/}
                                                {/*<div className="d-flex align-items-center me-5 me-xl-13">*/}
                                                {/*    <div className="symbol symbol-30px symbol-circle me-3">*/}
                                                {/*        <div className="symbol-label"*/}
                                                {/*             style={{background: 'rgba(255, 255, 255, 0.15)'}}*/}
                                                {/*        >*/}
                                                {/*            <KTSVG path="/media/icons/duotune/abstract/abs027.svg"*/}
                                                {/*                   className="svg-icon svg-icon-3 svg-icon-white"/>*/}
                                                {/*        </div>*/}
                                                {/*    </div>*/}
                                                {/*    <div className="m-0">*/}
                                                {/*        <a href="#" className="text-white opacity-75 fs-8">Poste par mois</a>*/}
                                                {/*        <span className="fw-bold text-white fs-7 d-block">1200</span>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                                {/*/!*end::Item*!/*/}
                                                {/*/!*begin::Item*!/*/}
                                                {/*<div className="d-flex align-items-center">*/}
                                                {/*    <div className="symbol symbol-30px symbol-circle me-3">*/}
                                                {/*        <div className="symbol-label"*/}
                                                {/*             style={{background: 'rgba(255, 255, 255, 0.15)'}}*/}
                                                {/*        >*/}
                                                {/*            <KTSVG path="/media/icons/duotune/abstract/abs043.svg"*/}
                                                {/*                   className="svg-icon svg-icon-3 svg-icon-white"/>*/}
                                                {/*        </div>*/}
                                                {/*    </div>*/}
                                                {/*    <div className="m-0">*/}
                                                {/*        <a href="#" className="text-white opacity-75 fs-8">Conversion</a>*/}
                                                {/*        <span className="fw-bold text-white fs-7 d-block">58%</span>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                                {/*/!*end::Item*!/*/}
                                            </div>
                                            {/*end::Items*/}

                                        </div>
                                        {/*end::Container*/}
                                        {/*begin::Action*/}
                                        <div className="m-0">
                                            <a href={'mailto:support@webforce.be'}
                                               className="btn btn-color-white bg-white bg-opacity-15 bg-hover-opacity-25 fw-semibold"
                                            >
                                                En savoir plus
                                            </a>
                                        </div>
                                        {/*end::Action*/}
                                    </div>
                                </div>
                                <div className="col-4 d-flex justify-content-end">
                                    <img src={`${data.announcement?.image}`}
                                         alt=""
                                         className="w-300px"/>
                                </div>
                            </div>
                        </div>
                        {/*begin::body*/}
                    </div>
                    {/*end::Engage widget*/}
                </div>
            </div>


            {/*begin::ROW-04 */
            }
            {/*card cursor */
            }
            <div className="row gy-5 g-xl-8">
                <div className="col-xl-6">
                    <ChartsWidget7 className={'card-xl-stretch'}/>
                </div>
                <div className="col-xl-6">
                    <ChartsWidget8 className={'card-xl-stretch'}/>
                </div>
            </div>
            {/*end::ROW-04 */
            }


        </>
    )
}


const DashboardWrapper: FC = () => {

    const intl = useIntl()

    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
            <DashboardPage />
        </>
    )
}

export {DashboardWrapper}