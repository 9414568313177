import React from 'react'
import {AsideDefault} from './components/aside/AsideDefault'
import {Footer} from './components/Footer'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {Toolbar} from './components/toolbar/Toolbar'
import {ScrollTop} from './components/ScrollTop'
import {Content} from './components/Content'
import {MasterInit} from './MasterInit'
import {PageDataProvider} from './core'
import {ActivityDrawer, DrawerContacts, DrawerMessenger, InviteUsers, Main, UpgradePlan} from '../partials'
import {DrawerCampaign} from '../../app/modules/products/mailing/components/campagnes/settings/create/DrawerCampaign'
import {ProductContext, ProductReturn} from '../../app/GeneraleVariables'
import {ChildrenInterface} from '../../global'
import {Product, ProductListItem, useUser} from '../../app/modules/context/types'
import ExtractDate from '../../app/modules/functions/ExtractDate'

const MasterLayout: React.FC<ChildrenInterface> = ({children}) => {

    const {products, products_list} = useUser()


    return (
        <PageDataProvider>
            <ProductContext.Provider value={ProductsData({products, productsList: products_list})}>

                <div className="page d-flex flex-row flex-column-fluid">
                    <AsideDefault/>
                    <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                        <HeaderWrapper/>

                        <div id="kt_content" className="content d-flex flex-column flex-column-fluid">
                            <Toolbar/>
                            <div className="post d-flex flex-column-fluid" id="kt_post">
                                <Content>{children}</Content>
                            </div>
                        </div>
                        <Footer/>
                    </div>
                </div>

                {/* begin:: Drawers */}
                <ActivityDrawer/>
                {/*<ExploreMain />*/}
                <DrawerMessenger/>
                <DrawerContacts/>
                <DrawerCampaign/>
                {/* end:: Drawers */}

                {/* begin:: Modals */}
                <Main/>
                <InviteUsers/>
                <UpgradePlan/>
                {/* end:: Modals */}

                <MasterInit/>
                <ScrollTop/>
            </ProductContext.Provider>
        </PageDataProvider>
    )
}

export {MasterLayout}

export default function ProductsData({products, productsList}: {
    products: Product,
    productsList: ProductListItem[]
}): ProductReturn[] {

    const productListNames = productsList.map(product => product.name)

    return productListNames.map(productName => {
        const productStatus: number = Number(products[productName as keyof Product]) // Ensure status is a number
        const productNameDateKey = productName + 'Date'
        const productDate = products[productNameDateKey as keyof Product]
        let dateFormatted = ''

        if (productDate) {
            const [dayString, longMonth, yearString] = ExtractDate({
                dateTime: `${productDate}`,
                separator: ' ',
                localeString: true,
            })
            dateFormatted = `${dayString}, ${longMonth} ${yearString}`;
        }

        return {
            name: productName,
            status: productStatus,
            date: dateFormatted,
            description: productsList.find(product => product.name === productName)?.description || '',
        }
    })
}
