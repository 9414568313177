import {createContext, useContext} from 'react'
import {SavContextInterface} from '../types'


const SavContext = createContext<SavContextInterface>(
    {
        data: undefined,
        setData: () => {},
        rangeValue: [new Date(), new Date()],
        setRangeValue: () => {},
        loadingStatus: 'idle',
        setLoadingStatus: () => {},
    }
)

const useSavContext = () => useContext(SavContext)

export {SavContext, useSavContext}

