import React, { FC, memo, useState } from 'react'
import { useQuery } from 'react-query'
import SetupAxios from '../../../setup/axios/SetupAxios'
import { ApiResponsesTicket, Attachment, responsesTicket } from './types'
import { useUser } from '../../modules/context/types'
import { toAbsoluteUrl } from '../../../_metronic/helpers'

const MESSAGE_TICKET = process.env.REACT_APP_SAV_TICKETS_MESSAGE_TICKET || 'sav/get-message'

const postData = async (formData: FormData): Promise<ApiResponsesTicket> => {
    try {
        const response = await SetupAxios.post<ApiResponsesTicket>(MESSAGE_TICKET, formData)
        return response.data
    } catch (error) {
        console.error('Error posting data:', error)
        throw error
    }
}

const validImg = ['png', 'jpg', 'jpeg']
const validDoc = ['pdf', 'docx', 'csv']

interface ResponsesInterface {
    ticketId: string
    refreshKey: number
}

const Responses: FC<ResponsesInterface> = memo(({ ticketId, refreshKey }) => {
    const { firstname, lastname } = useUser()
    const [responses, setResponses] = useState<responsesTicket[]>([])

    const formData = new FormData()
    formData.append('sav_ticket_id', ticketId)

    const fetchResponses = async () => postData(formData)

    useQuery(['responses_ticket', refreshKey], fetchResponses, {
        onSuccess: (data) => setResponses(data.messages),
    })

    const renderAttachment = (attachment: Attachment) => {

        const fileExtension = attachment.type.split('.').pop()?.toLowerCase() || ''
        if (validImg.includes(fileExtension)) {
            return (
                <img
                    src={attachment.src}
                    className="rounded w-50px h-50px me-2 mb-2"
                    alt={attachment.name}
                    key={attachment.name}
                />
            )
        } else if (validDoc.includes(fileExtension)) {
            return (
                <a href={attachment.src} target="_blank" download={attachment.name} key={attachment.name}>
                    <img
                        src={toAbsoluteUrl(`/media/svg/files/${fileExtension}.svg`)}
                        className="rounded w-50px h-50px me-2 mb-2"
                        alt={attachment.name}
                    />
                </a>
            )
        }
        return null
    }


    return (
        <div className="row">
            <div className="col-12 col-lg-8">
                {responses.map((response, index) => (
                    <div className="mb-10" key={index}>
                        <div className="card card-bordered w-100">
                            <div className="card-body p-5">
                                <div className="w-100 d-flex flex-stack mb-2">
                                    <div className="d-flex align-items-center">
                                        <div className="symbol symbol-50px me-5">
                                            <div
                                                className={`symbol-label fs-1 fw-bold ${
                                                    response.type === 'message' ? 'bg-light-success text-success' : 'bg-light-danger text-danger'
                                                }`}
                                            >
                                                {response.type === 'message' ? `${firstname[0]}${lastname[0]}` : 'WF'}
                                            </div>
                                        </div>
                                        <div className="d-flex flex-column fw-semibold fs-5 text-gray-600 text-gray-900">
                                            <div className="d-flex align-items-center">
                                                <a href="#" className="text-gray-800 fw-bold text-hover-primary fs-5 me-3">
                                                    {response.type === 'message' ? `${firstname} ${lastname}` : 'WebForce'}
                                                </a>
                                                <span className="m-0"></span>
                                            </div>
                                            <span className="text-muted fw-semibold fs-6">{response.created_at}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="fw-normal fs-5 text-gray-700 mb-3" dangerouslySetInnerHTML={{__html: response.message}}/>
                                {response.attachments?.length > 0 && response.attachments.map(renderAttachment)}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
})

export { Responses }
