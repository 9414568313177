import React, {FC, useCallback, useContext, useEffect, useMemo, useState} from 'react'
import {ValueType} from 'rsuite/cjs/DateRangePicker/types'
import {subDays} from 'date-fns'
import {API_URL, DateRange, localStorageValue} from '../../../modules/all-pages'
import {QueryFunctionContext, QueryKey, useQueries, useQuery} from 'react-query'
import {ChildrenInterface} from '../../../../global'
import {AdministrationData, initialState, InterfaceContext, ReportContext} from './types'
import {fetchAdministrationData, fetchDashboardData} from './functions'
import axios, {AxiosResponse} from 'axios'
import FileDownload from '../../../modules/functions/get/export/FileDownload'
import {useUser} from '../../../modules/context/types'

export interface Period {
    start: string;
    end: string;
}


const CONVERSION = process.env.REACT_APP_DASHBOARD_CONVERSION || 'conversion'
const SEO = process.env.REACT_APP_STATISTICS_SEO || 'statistics-seo'
const CALL_TRACKING = process.env.REACT_APP_CALL_TRACKING_GENERAL || 'calltraking-statistic'
const WEB_FORMS = process.env.REACT_APP_WEB_FORMS_HOME_WEB_FORMS || 'statistiques-generales'
const CAMPAIGN = process.env.REACT_APP_MAILING_STATISTICS_GLOBAL || 'v2/campagne-report'
const REPORT_PDF = process.env.REACT_APP_REPORT_PDF || 'v2/generate-report-pdf'


export const ReportProvider: FC<ChildrenInterface> = ({children}) => {

    const [rangeValue, setRangeValue] = useState<ValueType>([
        subDays(new Date(), 29), new Date(),
    ])
    const [dateTimeStart, dateTimeEnd] = rangeValue
    const [data, setData] = useState<InterfaceContext>({
        ...initialState,
        loadingStatus: 'idle',
    })
    const [loadPdf, setLoadPdf] = useState<'idle' | 'pending' | 'fulfilled' | 'rejected'>('idle')
    const {id} = useUser()

    const period = useMemo(() => {
        if (dateTimeStart && dateTimeEnd) {
            const start = `${dateTimeStart.getDate()}-${dateTimeStart.getMonth() + 1}-${dateTimeStart.getFullYear()}`
            const end = `${dateTimeEnd.getDate()}-${dateTimeEnd.getMonth() + 1}-${dateTimeEnd.getFullYear()}`
            setData(prevState => {
                return {...prevState, period}
            })
            return {start, end}
        }
        return {start: '', end: ''}
    }, [dateTimeStart, dateTimeEnd])

    const onSuccessReq = useCallback((dataReq: any, name: string) => {
        setData(prevData => {
            const updatedData = {...prevData}
            switch (name) {
                case 'conversion':
                    updatedData.conversion = dataReq
                    break
                case 'seo':
                    updatedData.seo = dataReq
                    updatedData.loadingStatus = 'fulfilled'
                    break
                case 'call_tracking':
                    updatedData.callReceived = dataReq
                    break
                case 'web_forms':
                    updatedData.formsReceived = dataReq
                    break
                case 'campagne':
                    updatedData.campagne = {
                        messagesReceived: dataReq.countMessages,
                        contacts: dataReq.countDataContacts,
                    }
                    break
                default:
                    break
            }
            return updatedData
        })
    }, [])

    const onError = useCallback((error: any) => {
        console.error('query: ', {error})
        setData((prevData) => ({
            ...prevData,
            loadingStatus: 'rejected',
        }))
    }, [])

    const queries = useMemo(() => [
        {name: 'conversion', endpoint: CONVERSION},
        {name: 'seo', endpoint: SEO},
        {name: 'call_tracking', endpoint: CALL_TRACKING},
        {name: 'web_forms', endpoint: WEB_FORMS},
        {name: 'campagne', endpoint: CAMPAIGN},
    ].map(query => ({
        queryKey: [`report_${query.name.toLowerCase()}`, period] as QueryKey,
        queryFn: (context: QueryFunctionContext<QueryKey, any>) => fetchDashboardData(query.endpoint, period),
        enabled: !!period.start && !!period.end,
        select: (data: any) => {


            switch (query.name) {
                case 'conversion':
                    return data
                case 'seo': {

                    if (data.status === 500)
                        return {
                            nombrePagesVues: 0,
                            nombreVisites: 0,
                            byChannels: [],
                            geographicOriginByCity: [],
                            mostViewedPage: [],
                        }
                    else return {
                        nombrePagesVues: data.vueAudience.nombrePagesVues,
                        nombreVisites: data.vueAudience.nombreVisites,
                        byChannels: data.acquisition.parCanaux,
                        geographicOriginByCity: data.vueParGeographie.origineGeographiqueVille,
                        mostViewedPage: data.contenu.pagesPlusConsultees,
                    }
                }
                case 'call_tracking':
                    return data.generalAppelRecu
                case 'web_forms':
                    return data.runningTotalFormReceived
                case 'campagne':
                    return {
                        countMessages: data.countData.countMessages,
                        countDataContacts: data.countData.countDataContacts,
                    }
                default:
                    return data
            }
        },
        onSuccess: (data: any) => onSuccessReq(data, query.name),
        onError,
    })), [period, onSuccessReq, onError])

    useQueries(queries)

    useEffect(() => {
        setData(prevData => ({
            ...prevData,
            loadingStatus: 'pending',
        }))
    }, [period])

    const onSuccess = (data: AdministrationData) => {
        setData(prevState => ({
            ...prevState,
            administration: {data, loading: false},
        }))
    }

    useQuery<AdministrationData, Error>('AdministrationData', fetchAdministrationData, {
        onSuccess,
    })


    useEffect(() => {
        if (id > 0) setLoadPdf('fulfilled')
    }, [id])

    const onSubmit = async (): Promise<void> => {
        setLoadPdf('pending')
        try {
            const response: AxiosResponse<Blob> = await axios({
                method: 'GET',
                url: `${API_URL}${REPORT_PDF}/${id}?start=${period.start}&end=${period.end}`,
                responseType: 'blob',
                headers: {
                    'Authorization': `Bearer ${localStorageValue}`,
                },
            })
            FileDownload({
                data: response.data,
                fileName: `Rapport ${period.start} ~ ${period.end}`,
                fileExtension: 'pdf',
            })
            setLoadPdf('fulfilled')
        } catch (error: any) {
            setLoadPdf('rejected')
        }
    }

    const PdfButton: FC = () => {
        const buttonClass = `btn btn-sm btn-flex fw-bolder btn-light-${
            loadPdf === 'fulfilled' ? 'success' : 'danger'} border-${
            loadPdf === 'fulfilled' ? 'success' : 'danger'} border-1 border-dashed ${
            loadPdf === 'fulfilled' ? '' : 'disabled'
        }`

        return (
            <button className={buttonClass} onClick={onSubmit} type="submit" disabled={loadPdf === 'pending'}>
                <i className="bi bi-file-pdf fs-2"/>
                <span>Pdf</span>
                {loadPdf === 'pending' && (
                    <span data-kt-indicator="on">
                        <span className="indicator-progress">
                            <span className="spinner-border spinner-border-sm align-middle ms-2"/>
                        </span>
                    </span>
                )}
            </button>
        )
    }

    return (
        <ReportContext.Provider value={data}>
            {/* RangeValue */}
            <div className="row mb-8">
                <div className="col-4">
                    <PdfButton/>
                </div>
                <div className="col-8 d-flex justify-content-end align-items-center">
                    {/*<DateRange*/}
                    {/*    rangeValue={rangeValue}*/}
                    {/*    setRangeValue={setRangeValue}*/}
                    {/*    loadingStatus={data.loadingStatus}*/}
                    {/*    setLoadingStatus={(status) => setData((prevData) => ({...prevData, loadingStatus: status}))}*/}
                    {/*    className="d-flex justify-content-end align-items-center"*/}
                    {/*/>*/}
                </div>
            </div>

            {children}
        </ReportContext.Provider>
    )
}

export const useReport = () => {
    return useContext(ReportContext)
}