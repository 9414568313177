import React from "react";
import axios from "axios";
import ErrorCatch from "../ErrorCatch";
import {API_URL, localStorageKey} from '../../all-pages'

export default function LoginPost(
    data: object,
    setRedirect: any,
    setLoading: any,
    setSubmitting: any,
    setStatus: any,
    msgSuccess: string,
    msgError: string,
    setColor: any
) {

    const LOGIN = process.env.REACT_APP_LOGIN;

    axios({

        method: 'POST',
        url: `${API_URL}${LOGIN}`,
        headers: { },
        data: data
    })
        .then(function (response) {

            const {data} = response
            const {status, message} = data;

            if (status === 403 && message !== "") {

                setStatus(message)
            } else {
                localStorage.setItem(localStorageKey, response.data.token);
                setRedirect(true);
                window.location.reload();
            }


        })
        .catch(function () {

            ErrorCatch(setLoading, setSubmitting, setStatus, msgSuccess, msgError, setRedirect, null, setColor);
        });
}
