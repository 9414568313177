import React, {FC} from 'react'
import clsx from 'clsx'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {HeaderUserMenu} from '../../../partials'
import {useUser} from '../../../../app/modules/context/types'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
    toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
    toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
    toolbarButtonIconSizeClass = 'svg-icon-1'

const Topbar: FC = () => {

    const {firstname, lastname, images} = useUser()
    // const {notifications: {ticket_message}} = useNotifications()

    const image = images.length ? images[0]['path'] : toAbsoluteUrl('/media/avatars/blank.png')

    return (
        <div className="d-flex align-items-stretch flex-shrink-0">

            {/* NOTIFICATIONS */}
            {/*<div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>*/}
            {/*    <div className={clsx(*/}
            {/*        'btn btn-icon btn-active-light-primary position-relative',*/}
            {/*        toolbarButtonHeightClass,*/}
            {/*    )}*/}
            {/*         data-kt-menu-trigger="click"*/}
            {/*         data-kt-menu-attach="parent"*/}
            {/*         data-kt-menu-placement="bottom-end"*/}
            {/*         data-kt-menu-flip="bottom"*/}
            {/*    >*/}
            {/*        <KTSVG*/}
            {/*            path="/media/icons/duotune/general/gen022.svg"*/}
            {/*            className={toolbarButtonIconSizeClass}*/}
            {/*        />*/}
            {/*        {*/}
            {/*            ticket_message && ticket_message?.count > 0 && (*/}
            {/*                <span*/}
            {/*                    className="fs-9 text-success position-absolute translate-middle top-100 start-50 animation-blink">{ticket_message?.count}</span>*/}
            {/*            )*/}
            {/*        }*/}
            {/*    </div>*/}
            {/*    <HeaderNotificationsMenu />*/}
            {/*</div>*/}

            {/* begin::User */}
            <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
                 id="kt_header_user_menu_toggle"
            >
                {/* begin::Toggle */}
                <div
                    className={clsx('cursor-pointer symbol d-flex align-items-center', toolbarUserAvatarHeightClass)}
                    data-kt-menu-trigger="click"
                    data-kt-menu-attach="parent"
                    data-kt-menu-placement="bottom-end"
                    data-kt-menu-flip="bottom"
                >
                    <span className="cursor-pointer text-gray-600 text-hover-primary fs-6 fs-sm-5 me-3 text-capitalize">
                        {firstname} {lastname}
                    </span>
                    <img src={image} alt="MyWebforce" style={{objectFit: 'cover'}} />
                </div>
                <HeaderUserMenu />
                {/* end::Toggle */}
            </div>
            {/* end::User */}

        </div>
    )
}

export {Topbar}