import {useMemo} from 'react'

const usePeriod = (dateTimeStart?: Date, dateTimeEnd?: Date) => {

    return useMemo(() => {

        if (dateTimeStart && dateTimeEnd) {
            return {
                start: `${dateTimeStart.getDate()}-${dateTimeStart.getMonth() + 1}-${dateTimeStart.getFullYear()}`,
                end: `${dateTimeEnd.getDate()}-${dateTimeEnd.getMonth() + 1}-${dateTimeEnd.getFullYear()}`,
            };
        }
        return { start: '', end: '' };
    }, [dateTimeStart, dateTimeEnd])
}

export {usePeriod}