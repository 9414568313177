import axios, { AxiosInstance } from 'axios';
import {localStorageValue} from '../../app/modules/all-pages'

const REACT_APP_API_URL = process.env.REACT_APP_API_URL

const SetupAxios: AxiosInstance = axios.create({
    baseURL: REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
    timeout: 10000,
});

SetupAxios.interceptors.request.use(
    config => {
        // Modify request config here (e.g., add auth token)
        if (localStorageValue) {
            config.headers.Authorization = `Bearer ${localStorageValue}`
        }

        return config
    },
    error => {
        return Promise.reject(error);
    }
);

SetupAxios.interceptors.response.use(
    response => {
        // Handle the response data here
        return response;
    },
    error => {
        // Handle errors here
        return Promise.reject(error);
    }
);

export default SetupAxios;
