import {ReportHeader} from './component/ReportHeader'
import {Conversion} from './Conversion'
import {SeoStats} from './component/SeoStats'
import React, {FC, memo, ReactNode, useRef} from 'react'
import {useReport} from './context/ReportProvider'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PDFDownloadLink, PDFViewer} from '@react-pdf/renderer'
import {GeneratePDF} from './pdf/GeneratePDF'

const imgPath = toAbsoluteUrl('/media/illustrations/sigma-1/21.png')
const imgPath2 = toAbsoluteUrl('/media/illustrations/sigma-1/23.png')
const imgPath3 = toAbsoluteUrl('/media/logos/Logo-WF_1.png')


const ReportContent = memo(() => {

    const ref = useRef<HTMLDivElement | null>(null)

    const {
        period, conversion, loadingStatus, seo, campagne, formsReceived, callReceived, domainName, administration,
    } = useReport()


    function stripHtml(html: string): string {
        const div = document.createElement('div')
        div.innerHTML = html
        return div.textContent || div.innerText || ''
    }

    const {
        data: {position_top_5, pub_meddle, partage_media_1, partage_media_2},
        loading: loadingAdministration,
    } = administration

    return (
        <>
            {/*{*/}
            {/*    loadingStatus === 'pending' ? (*/}
            {/*        <button*/}
            {/*            className={'btn btn-sm btn-flex fw-bolder me-3 btn-light-danger border-danger border-1 border-dashed mb-5 disabled'}>*/}
            {/*            <i className="bi bi-file-pdf fs-1"/>*/}
            {/*            Pdf*/}
            {/*        </button>*/}
            {/*    ) : (*/}
            {/*        <PDFDownloadLink*/}
            {/*            document={<GeneratePDF data={{*/}
            {/*                period,*/}
            {/*                conversion,*/}
            {/*                seo,*/}
            {/*                campagne,*/}
            {/*                formsReceived,*/}
            {/*                callReceived,*/}
            {/*                loadingStatus,*/}
            {/*                administration,*/}
            {/*                domainName*/}
            {/*            }}/>}*/}
            {/*            fileName="Rapport.pdf">*/}
            {/*            {({blob, url, error}) =>*/}
            {/*                <button*/}
            {/*                    className={'btn btn-sm btn-flex fw-bolder me-3 btn-light-success border-success border-1 border-dashed mb-5'}>*/}
            {/*                    <i className="bi bi-file-pdf fs-1"/>*/}
            {/*                    Pdf*/}
            {/*                </button>*/}
            {/*            }*/}
            {/*        </PDFDownloadLink>*/}
            {/*    )*/}
            {/*}*/}


            {/*<PDFViewer style={{width: '100%', height: '100%'}}>*/}
            {/*    <GeneratePDF data={{*/}
            {/*        period,*/}
            {/*        conversion,*/}
            {/*        seo,*/}
            {/*        campagne,*/}
            {/*        formsReceived,*/}
            {/*        callReceived,*/}
            {/*        loadingStatus,*/}
            {/*        administration,*/}
            {/*        domainName*/}
            {/*    }}/>*/}
            {/*</PDFViewer>*/}

            <div ref={ref} className="position-relative">

                <ReportHeader/>

                <Conversion/>

                <div className="row p-5 py-10 mb-10 position-relative" style={{backgroundColor: '#8bcefb'}}>
                    <img src={imgPath} alt="mywebforce"
                         style={{width: 120, height: 140, position: 'absolute', right: 0, zIndex: 2}}/>
                    <SeoStats/>
                    <div className="row g-5">
                        <div className="col-12">
                            <div className="card card-flush h-xl-100 bg-white">
                                <div className="card-body">
                                    <IsLoading slice={2} alignItems={'center'} loading={loadingAdministration}
                                               height={'h-20px'}>
                                        <div className="text-primary fw-bold fs-3 text-center px-5">
                                            {stripHtml(position_top_5.title)}
                                        </div>
                                    </IsLoading>
                                </div>
                                <div className="card-footer pt-0 d-flex justify-content-center align-items-center">
                                    <IsLoading slice={3} alignItems={'center'} loading={loadingAdministration}
                                               height={'h-15px'}>
                                        <div className="text-gray-900 fw-bold fs-6 text-center px-5">
                                            {stripHtml(position_top_5.text)}
                                        </div>
                                    </IsLoading>
                                    {/*{*/}
                                    {/*    loadingAdministration ? (*/}
                                    {/*        <div className="w-25">*/}
                                    {/*            <div className="bg-light-dark h-30px"></div>*/}
                                    {/*        </div>*/}
                                    {/*    ) : (*/}
                                    {/*        <a href={position_top_5.button?.href} target="_blank"*/}
                                    {/*           className="btn btn-sm btn-color-gray-100"*/}
                                    {/*           rel="noreferrer"*/}
                                    {/*           style={{backgroundColor: '#5bbeff'}}*/}
                                    {/*        >*/}
                                    {/*            {position_top_5.button?.title}*/}
                                    {/*        </a>*/}
                                    {/*    )*/}
                                    {/*}*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row p-5 py-10 mb-5 bg-white">
                    <div className="col-lg-6">
                        <img src={`${pub_meddle.image}`} className="w-400px" alt=""/>
                    </div>
                    <div className="col-lg-6">
                        <div className="card card-flush h-xl-100">
                            <div className="card-body">
                                <IsLoading slice={2} alignItems={'start'} loading={loadingAdministration}>
                                    <h1 className="fs-3 text-primary lh-lg mb-5">{pub_meddle.title}</h1>
                                </IsLoading>
                                <IsLoading slice={1} alignItems={'start'} loading={loadingAdministration}>
                                    <h5 className="mb-3"
                                        style={{color: 'rgb(250, 69, 156)'}}>{pub_meddle.sub_title}</h5>
                                </IsLoading>

                                <IsLoading slice={4} alignItems={'start'} loading={loadingAdministration}
                                           height={'h-20px'}>
                                    <div className="text-gray-800 fw-bold fs-6">
                                        {stripHtml(pub_meddle.text)}
                                    </div>
                                </IsLoading>

                            </div>
                            <div className="card-footer pt-0">

                                {
                                    loadingAdministration ? (
                                        <div className="w-25">
                                            <div className="bg-light-dark h-30px"></div>
                                        </div>
                                    ) : (
                                        <a href={pub_meddle.button?.href} target="_blank"
                                           className="btn btn-sm text-white"
                                           rel="noreferrer"
                                           style={{backgroundColor: 'rgb(250, 69, 156)'}}
                                        >
                                            {pub_meddle.button?.title}
                                        </a>
                                    )
                                }

                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mb-10 position-relative" style={{
                    backgroundColor: '#a6daff',
                    paddingBottom: 170,
                    paddingTop: 30,
                    paddingLeft: 20,
                }}>
                    <div className="col-7">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-10">
                                        {
                                            loadingAdministration
                                                ? (
                                                    <>
                                                        <div className="bg-light-dark w-40px h-40px"/>
                                                        <div className="w-100 bg-light-dark h-25px my-5"/>
                                                    </>
                                                )
                                                : (
                                                    <>
                                                        <div
                                                            className="d-flex flex-center w-40px h-40px rounded-2" style={{backgroundColor: '#cdecff'}}>
                                                            <img src={`${partage_media_1.icon}`} className="w-30px h-30px"
                                                                 alt=""/>
                                                        </div>
                                                        <div
                                                            className="text-primary fw-bold my-5 fs-3">{partage_media_1.title}</div>
                                                    </>
                                                )
                                        }
                                        <IsLoading slice={3} alignItems={'start'} loading={loadingAdministration}
                                                   height={'h-15px'}>
                                            <div className="text-gray-800 text-start fs-7">
                                                {partage_media_1.text}
                                            </div>
                                        </IsLoading>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                    <div className="col-6" style={{
                        position: 'absolute', top: 150, right: 20,
                    }}>
                        <img src={imgPath2} alt=""
                             style={{
                                 position: 'absolute', top: -120, right: 0, zIndex: 1,
                             }}
                        />

                        <div className="card bg-primary">
                            <div className="card-body">
                                {
                                    loadingAdministration
                                        ? (
                                            <>
                                                <div className="bg-light-dark w-40px h-40px"/>
                                                <div className="w-100 bg-light-dark h-25px my-5"/>
                                            </>
                                        )
                                        : (
                                            <>
                                                <div
                                                    className="d-flex flex-center w-40px h-40px rounded-2 bg-white bg-opacity-90">
                                                    <img src={`${partage_media_2.icon}`} className="w-30px h-30px"
                                                         alt=""/>

                                                </div>
                                                <div
                                                    className="text-gray-100 fw-bold my-5 fs-3">{partage_media_2.title}</div>
                                            </>
                                        )
                                }

                                <IsLoading slice={3} alignItems={'start'} loading={loadingAdministration}
                                           height={'h-15px'}>
                                    <div className="text-gray-100 text-start fs-7">
                                        {partage_media_2.text}
                                    </div>
                                </IsLoading>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row p-5">
                    <div className="col-4">
                        <img src={imgPath3} style={{width: '260px'}} alt="mywebforce"/>
                        <p className="fs-6 text-primary mt-5 mb-1">TVA intra-communautaire BE 0632694673</p>
                    </div>
                    <div className="col-8">
                        <div className="h-50px"></div>
                        <p className="fs-6 text-primary mb-5">Webforce In Touch est développé par Webforce Benelux Parc
                            de l'Alliance, Avenue du Japon 1 1420 Braine l'Alleud</p>
                        <p className="fs-6 text-primary mb-1">Tél: +32 2 386 03 00 ( Appel local non surtaxé )</p>
                    </div>
                </div>

            </div>
        </>
    )
})

export {ReportContent}

type PropsIsLoading = {
    alignItems: 'start' | 'center';
    children: ReactNode;
    loading: boolean;
    slice: number;
    height?: string
}

const IsLoading: FC<PropsIsLoading> = ({alignItems, children, loading, height, slice}) => {

    const array = [
        {width: 'w-100'},
        {width: 'w-75'},
        {width: 'w-25'},
        {width: 'w-100'},
        {width: 'w-100'},
        {width: 'w-25'},
    ]

    return (
        <>
            {
                loading ? (
                    <div className={`w-100 d-flex justify-content-center align-items-${alignItems} flex-column`}>
                        {
                            array.slice(0, slice).map(({width}, index) => (
                                <div className={`bg-light-dark mb-3 ${width} ${height ? height : 'h-30px'}`}
                                     key={index}/>
                            ))
                        }
                    </div>
                ) : children
            }

        </>
    )
}

